import {IBaseEntity, BaseEntity} from 'app/blocks/model/base-entity.model';
import {IEmployee} from 'app/blocks/model/employee.model';
import {IRoute} from 'app/blocks/model/route.model';
import {IVehicle} from 'app/blocks/model/vehicle.model';

export interface IEodSurvey extends IBaseEntity {
    id?: number;
    route?: IRoute;
    date?: Date;
    time?: any;
    driver?: IEmployee;
    vehicle?: IVehicle;
    endingMileage?: number;
    temperature?: number;
    cratesReturned?: number;
}

export class EodSurvey extends BaseEntity implements IEodSurvey {
    public id?: number;
    public route?: IRoute;
    public date?: Date;
    public time?: any;
    public driver?: IEmployee;
    public vehicle?: IVehicle;
    public endingMileage?: number;
    public temperature?: number;
    public cratesReturned?: number;

    constructor(eodSurvey?: any) {
        super();
        if (eodSurvey) {
            this.id = eodSurvey.id;
            this.route = eodSurvey.route;
            this.date = eodSurvey.date;
            this.time = eodSurvey.time;
            this.driver = eodSurvey.driver;
            this.vehicle = eodSurvey.vehicle;
            this.endingMileage = eodSurvey.endingMileage;
            this.temperature = eodSurvey.temperature;
            this.cratesReturned = eodSurvey.cratesReturned;
        }
    }

    clone(): IBaseEntity {
        return new EodSurvey(this);
    }
}
